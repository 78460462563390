import countries from '@/app/config/world.json';

export const GuestBookingSchema = () => {
  return [
    {
      class: 'group grid px-4 pt-5 text-xl',
      type: 'group',
      label: 'Personal Information',
      help: 'Please provide us with the following information in order to check you in for your upcoming stay. This information is required and will be used for our indemnity and/or terms & conditions in the next step.',
    },
    {
      component: 'div',
      class: 'grid gap-6 px-4 pb-5 mb-6',
      children: [
        {
          type: 'text',
          name: 'firstName',
          label: 'First name',
          validation: 'required:trim',
          'validation-name': 'Name',
          'keep-model-data': true,
        },
        {
          type: 'text',
          name: 'lastName',
          label: 'Last name',

          validation: 'required:trim',
          'validation-name': 'Last name',
          'keep-model-data': true,
        },
        {
          type: 'email',
          name: 'email',
          label: 'Email',
          validation: 'required:trim',
          'validation-name': 'Email',
          'keep-model-data': true,
        },
        {
          type: 'countryCode',
          label: 'Phone number',
          name: 'phone',
          'keep-model-data': true,
          'validation-messages': {
            phone: 'Phone is required',
          },
          'error-behaviour': 'blur',
          validation: 'required|phone',
        },
        {
          class: 'grid',
          type: 'objectPersonal',
          name: 'address',
          id: 'address',
          'keep-model-data': true,
          options: [
            {
              component: 'div',
              children: 'Residential address',
              class: 'font-semibold text-sm mb-0.5',
            },
            {
              component: 'div',
              class: 'grid gap-6 grid-cols-6',
              id: 'address-wrapper',
              children: [
                {
                  type: 'text',
                  name: 'addressLineOne',
                  id: 'addressLineOne',
                  placeholder: 'Address line 1',
                  'validation-name': 'Address',
                  validation: 'required:trim',
                  'validation-behaviour': 'live',
                  'keep-model-data': true,
                  class: 'col-span-6 sm:col-span-6',
                },
                {
                  type: 'text',
                  name: 'addressLineTwo', // new field added
                  id: 'addressLineTwo',
                  placeholder: 'Address line 2',
                  'keep-model-data': true,
                  class: 'col-span-6 sm:col-span-6',
                },
                {
                  type: 'text',
                  name: 'city',
                  id: 'city',
                  placeholder: 'City',
                  validation: 'required:trim',
                  'validation-behaviour': 'live',
                  'keep-model-data': true,
                  class: 'col-span-6 sm:col-span-3',
                },
                {
                  type: 'text', // New field added
                  name: 'province',
                  id: 'province',
                  placeholder: 'State/Region/Province',
                  validation: 'required:trim',
                  'validation-behaviour': 'live',
                  'keep-model-data': true,
                  class: 'col-span-6 sm:col-span-3',
                },

                {
                  type: 'text',
                  name: 'code',
                  id: 'code',
                  placeholder: 'Postal Code/ ZIP',
                  validation: 'required:trim',
                  'validation-behaviour': 'live',
                  'keep-model-data': true,
                  class: 'col-span-6 sm:col-span-2',
                },
                {
                  type: 'selector',
                  options: countries,
                  name: 'country',
                  id: 'country',
                  placeholder: 'Country',
                  validation: 'required:trim',
                  'validation-behaviour': 'live',
                  'keep-model-data': true,
                  class: 'col-span-6 sm:col-span-4',
                },
              ],
            },
          ],
        },
        // {
        //   component: 'div',
        //   class: 'grid gap-6 grid-cols-6',
        //   children: [
        //     {
        //       type: 'radio',
        //       name: 'resident',
        //       label: `Are you a resident of ${accommodation.address.formatted_address.split(',').slice(-1)[0]} ?`,
        //       options: { Yes: 'Yes', No: 'No' },
        //       validation: 'required',
        //       'keep-model-data': true,
        //       class: 'col-span-6 sm:col-span-6',
        //     },
        //   ],
        // },
        {
          component: 'div',
          class: 'grid gap-6 grid-cols-6',
          children: [
            {
              type: 'selector',
              options: countries,
              name: 'resident',
              label: 'Country of residence',
              validation: 'required:trim',
              'validation-behaviour': 'live',
              'keep-model-data': true,
              'validation-name': 'Country of residence',
              class: 'col-span-6 sm:col-span-6',
            },
          ],
        },
        {
          type: 'selector',
          options: countries,
          label: 'Nationality',
          name: 'nationality',
          placeholder: 'Select country of origin',
          validation: 'required:trim',
          'validation-behaviour': 'live',
          'keep-model-data': true,
        },
        {
          component: 'div',
          class: 'grid gap-6 grid-cols-6',

          children: [
            {
              label: 'Passport or ID number',
              name: 'passportId',
              placeholder: 'Passport or ID number',
              'validation-name': 'Passport/ID',
              'keep-model-data': true,
              validation: 'required:trim',
              'validation-behaviour': 'live',
              class: 'col-span-6 sm:col-span-3',
            },
            {
              type: 'dobPicker',
              label: 'Date of birth',
              'keep-model-data': true,
              name: 'dateOfBirth',
              class: 'col-span-6 sm:col-span-3',
            },
          ],
        },
        {
          type: 'image',
          name: 'travelIdDocument',
          label: 'Upload your travel or identification document.',
          help: 'Upload a picture from your phone or a PDF.',
          'upload-behavior': 'live',
          'keep-model-data': true,
          validation: 'required',
          'validation-behaviour': 'live',
          'validation-name': 'Identification',
        },
      ],
    },
    {
      component: 'hr',
      class: 'mx-4 mt-6 mb-10',
      name: 'profileHR',
    },
    {
      class: 'group grid px-4 pt-5 text-xl',
      type: 'group',
      label: 'Medical Aid and Health Information (Optional)',
      help: 'Please provide the following additional information if you deem it necessary.',
      name: 'profileDescription',
    },
    {
      class: 'grid gap-6 px-4 mb-6 ',
      name: 'profile',
      'keep-model-data': true,
      type: 'objectMedical',
      id: 'profile',
      options: [
        {
          component: 'div',
          class: 'grid grid-cols-6 gap-6 mt-6',
          children: [
            {
              type: 'radio',
              options: {
                Yes: 'Yes',
                No: 'No',
              },
              label: 'Do you have travel Insurance?',
              'keep-model-data': true,
              class: 'col-span-6 sm:col-span-3',
              name: 'travelInsurance',
              id: 'travelInsurance',
              // 'validation-name': 'Travel insurance',
              // validation: 'required',
            },
            {
              type: 'text',
              label: 'Medical Aid Provider',
              'keep-model-data': true,
              placeholder: 'Medical Aid Provider',
              class: 'col-span-6 sm:col-span-3',
              name: 'medicalAidProvider',
              id: 'medicalAidProvider',
              // 'validation-name': 'Medical Aid Provider',
              // validation: 'required:trim',
            },
            {
              type: 'text',
              label: 'Medical Aid Policy number',
              name: 'medicalAidNumber',
              placeholder: 'Medical Aid policy number',
              class: 'col-span-6 sm:col-span-3',
              'keep-model-data': true,
              // validation: 'required:trim',
              // 'validation-name': 'Medical Aid Policy number',
            },

            {
              type: 'countryCode',
              name: 'medicalAidEmergency',
              id: 'medicalAidEmergency',
              label: 'Medical Aid Emergency contact number',
              // 'validation-messages': {
              //   phone: 'Number is required',
              // },
              // 'error-behaviour': 'blur',
              // validation: 'required|phone',
              class: 'col-span-6 sm:col-span-3',
              'keep-model-data': true,
            },
            {
              type: 'countryCode',
              label: 'Next of Kin contact number',
              name: 'emergencyContact',
              id: 'emergencyContact',
              class: 'col-span-6 sm:col-span-3',
              'keep-model-data': true,
              // 'validation-messages': {
              //   phone: 'Number is required',
              // },
              // 'error-behaviour': 'blur',
              // validation: 'required|phone',
            },
            {
              type: 'textarea',
              label: 'Do you have any dietary requirements?',
              placeholder: 'Vegan, Vegetarian, Gluten free, Pescatarian, etc.',
              name: 'dietaryRequirements',
              id: 'dietaryRequirements',
              'keep-model-data': true,
              class: 'col-span-6 sm:col-span-3',
              'input-class': 'p-2',
              // validation: 'required:trim',
              // 'validation-name': 'Dietary requirements',
            },
            {
              type: 'textarea',
              label: 'Do you have any allergies?',
              placeholder: 'Lactose, Nut, etc.',
              name: 'allergies',
              id: 'allergies',
              'keep-model-data': true,
              class: 'col-span-6 sm:col-span-3',
              'input-class': 'p-2',
              // validation: 'required:trim',
              // 'validation-name': 'Allergies',
            },
            {
              type: 'textarea',
              label: 'Do you have any chronic medical conditions?',

              name: 'medicalConditions',
              id: 'medicalConditions',
              'keep-model-data': true,
              class: 'col-span-6 sm:col-span-3',
              'input-class': 'p-2',
              // validation: 'required:trim',
              // 'validation-name': 'Medical conditions',
            },
            {
              type: 'textarea',
              label: 'Do you have any special accessibility requirements?',

              name: 'specialRequirements',
              id: 'specialRequirements',
              'keep-model-data': true,
              class: 'col-span-6 sm:col-span-6',
              'input-class': 'p-2',
              // validation: 'required:trim',
              // 'validation-name': 'Special accessibility requirements',
            },
            {
              type: 'radio',
              options: {
                Yes: 'Yes',
                No: 'No',
              },
              label: 'Are you vaccinated against COVID-19?',
              'keep-model-data': true,
              class: 'col-span-6 sm:col-span-3',
              name: 'vaccinated',
              id: 'vaccinated',
              // validation: 'required',
              // 'validation-name': 'Are you vaccinated',
            },
            {
              type: 'radio',
              options: {
                Yes: 'Yes',
                No: 'No',
              },
              label: 'Is your COVID-19 vaccine up to date?',
              'keep-model-data': true,
              class: 'col-span-6 sm:col-span-3',
              name: 'vaccineUpToDate',
              id: 'vaccineUpToDate',
              // validation: 'required',
              // 'validation-name': 'Vaccine up to date',
            },
            {
              type: 'image',
              name: 'vaccinationProof',
              class: 'col-span-6 sm:col-span-6',
              label: 'Upload proof of COVID-19 vaccination',
              help: 'Upload an image from your phone or a PDF.',
              'upload-behavior': 'live',
              'keep-model-data': true,
              // validation: 'required',
              // 'validation-name': 'Proof of vaccination',
              // 'validation-behaviour': 'live',
            },
          ],
        },
      ],
    },

    {
      component: 'hr',
      class: 'mx-4 mt-6 mb-10',
      name: 'guestArrivalHR',
    },
    {
      class: 'group grid px-4 pt-5 text-xl',
      type: 'group',
      label: 'Arrival Information (Optional)',
      name: 'guestArrivalDescription',
      help: 'To further streamline your check-in process, please provide your accommodation with the following details.',
    },
    {
      class: 'grid gap-6 px-4 mb-6',
      type: 'objectArrival',
      name: 'guestArrival',
      'keep-model-data': true,
      id: 'guestArrival',

      options: [
        {
          component: 'div',
          class: 'grid grid-cols-6 gap-6 mt-4',
          children: [
            {
              name: 'luggageHandling',
              label: 'Do you need assistance with your luggage when you arrive?',
              'keep-model-data': true,
              type: 'radio',
              options: {
                Yes: 'Assist with luggage',
                No: 'Do not need assistance',
                'N/A': 'Not applicable',
              },
              class: 'col-span-6 sm:col-span-6',
              // validation: 'required',
              // 'validation-name': 'Luggage handling',
            },
            {
              type: 'timepicker',
              name: 'arrivalTimeStart',
              id: 'arrivalTimeStart',
              label: 'Approximate arrival time from',
              pattern: '[0-9]*',
              class: 'w-auto col-span-6 sm:col-span-3',
              'keep-model-data': true,
              // validation: 'required',
              // 'validation-name': 'Arrival time',
            },
            {
              type: 'timepicker',
              name: 'arrivalTimeEnd',
              id: 'arrivalTimeEnd',
              label: 'To',
              class: 'w-full col-span-6 sm:col-span-3',
              pattern: '[0-9]*',
              'keep-model-data': true,
              // validation: 'required',
              // 'validation-name': 'Arrival time',
            },
            {
              type: 'select',
              options: ['Self drive', 'Transfer', 'Flight', 'Rail', 'Bus', 'Taxi/Uber'],
              name: 'modeTransport',
              id: 'modeTransport',
              label: 'Mode of transport',
              placeholder: 'Select',

              class: 'w-full col-span-6 sm:col-span-3 ',
              'keep-model-data': true,
              // 'validation-name': 'Mode of transport',
              // validation: 'required',
            },
            {
              type: 'text',
              name: 'vehicleRegistration',
              id: 'vehicleRegistration',
              label: 'Vehicle registration',

              class: 'w-full col-span-6 sm:col-span-3 ',
              'keep-model-data': true,
              // 'validation-name': 'Vehicle registration',
              // validation: 'required:trim',
            },
            {
              type: 'text',
              name: 'flightNumber',
              id: 'flightNumber',
              label: 'Flight number',

              class: 'w-full col-span-6 sm:col-span-3 ',
              'keep-model-data': true,
              // 'validation-name': 'Flight number',
              // validation: 'required:trim',
            },
            {
              type: 'text',
              name: 'airline',
              id: 'airline',
              label: 'Airline',

              class: 'w-full col-span-6 sm:col-span-3 ',
              'keep-model-data': true,
              // 'validation-name': 'Airline',
              // validation: 'required:trim',
            },
          ],
        },
      ],
    },
    {
      component: 'hr',
      class: 'mx-4 mt-6 mb-10',
      name: 'additionalInformationHR',
    },
    {
      class: 'group grid px-4 pt-5 text-xl',
      type: 'group',
      label: 'Additional Information (Optional)',
      help: 'Please provide your accommodation with the following additional information.',
      name: 'additionalInformationDescription',
    },
    {
      class: 'grid gap-6 px-4 mb-6',
      type: 'objectExtraInfo',
      name: 'guestAdditionalInformation',
      'keep-model-data': true,
      id: 'guestAdditionalInformation',
      options: [
        {
          component: 'div',
          class: 'grid grid-cols-6 gap-6 mt-4',
          children: [
            {
              type: 'text',
              name: 'roomSharing',
              id: 'roomSharing',
              label: 'Which guests will you be sharing a room with?',

              class: 'w-full col-span-6 sm:col-span-6 ',
              'keep-model-data': true,
              // 'validation-name': 'Room sharing',
              // validation: 'required:trim',
            },
            {
              name: 'bedConfig',
              label: 'How would you like your beds set up?',
              'keep-model-data': true,
              type: 'radio',
              options: [
                { value: 'One king-size bed', label: 'One king-size bed' },
                { value: 'Two singles', label: 'Two singles' },
              ],
              class: 'col-span-6 sm:col-span-3',
              // validation: 'required',
              // 'validation-name': 'Bed setup',
            },

            {
              name: 'infantCots',
              label: 'Do you require cots for infants?',
              'keep-model-data': true,
              type: 'radio',
              options: {
                Yes: 'Yes',
                No: 'No',
              },
              class: 'col-span-6 sm:col-span-3',
              // validation: 'required',
              // 'validation-name': 'Infant cots',
            },
            {
              name: 'bushDinner',
              label: 'Would you like a bush dinner?',
              'keep-model-data': true,
              type: 'radio',
              options: {
                Yes: 'Yes',
                No: 'No',
              },
              class: 'col-span-6 sm:col-span-3',
              // validation: 'required',
              // 'validation-name': 'Bush dinner',
            },
            {
              name: 'bushWalk',
              label: 'Would you like to do a bush walk?',
              'keep-model-data': true,
              type: 'radio',
              options: {
                Yes: 'Yes',
                No: 'No',
              },
              class: 'col-span-6 sm:col-span-3',
              // validation: 'required',
              // 'validation-name': 'Bush walk',
            },
            {
              type: 'textarea',
              name: 'specialOccasion',
              id: 'specialOccasion',
              label: 'Are you celebrating a special occasion? If yes, please provides details.',

              class: 'w-full col-span-6 sm:col-span-6 ',
              'keep-model-data': true,
              // 'validation-name': 'Special occasion',
              // validation: 'required:trim',
            },
            {
              type: 'textarea',
              name: 'specificInterests',
              id: 'specificInterests',
              label: 'Do you have any specific interests?',

              class: 'w-full col-span-6 sm:col-span-6 ',
              'keep-model-data': true,
              // 'validation-name': 'Specific interests',
              // validation: 'required:trim',
            },
            {
              name: 'firearm',
              label: 'Will you be carrying a firearm with you?',
              'keep-model-data': true,
              type: 'radio',
              options: {
                Yes: 'Yes',
                No: 'No',
              },
              class: 'col-span-6 sm:col-span-6',
              // validation: 'required',
              // 'validation-name': 'Carrying a firearm',
            },
          ],
        },
      ],
    },
  ];
};
