








































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import ActiveStep from './ActiveStep.vue';
import CompletedStep from './CompletedStep.vue';

@Component({
  components: {
    ActiveStep,
    CompletedStep,
  },
})
export default class Stepper extends Vue {
  @PropSync('activeStep') activeStepSync: number;
  @Prop() steps: string[];
  @Prop() click = true;
  @Prop() isEditingSync: boolean;

  stepperSubmit = false;

  tabSwitch(index: number) {
    if (this.click) {
      if (this.isEditingSync || this.activeStepSync >= index)
        if (this.activeStepSync === 0 || this.activeStepSync === 2 || this.activeStepSync === 4) {
          this.stepperSubmit = true;
          this.$emit('save', this.stepperSubmit, index);
        } else this.activeStepSync = index;
      this.stepperSubmit = false;
    }
  }
}
