





































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CompletedStep extends Vue {
  @Prop() label: string;
}
