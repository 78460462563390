






















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ActiveStep extends Vue {
  @Prop() step: number;
  @Prop() label: string;
  @Prop() active: boolean;
}
